<template>
  <div class="search">
    <el-row :gutter="30">
      <template v-for="(item,index) in search">
        <el-col :span="24"
                class="flex mb-10"
                :key="'area'+index"
                v-if="item.type === 'area'">
          <span class="search-name mr-15">地区</span>
          <Area ref="areaC"
                style="flex:1"
                v-model="areaData"
                :level="item.level || 4"
                @change="areaChange" />
        </el-col>

        <el-col :span="24"
                class="flex mb-10"
                :key="'address'+index"
                v-else-if="item.type === 'address'">
          <span class="search-name mr-15">地区</span>
          <Address ref="address"
                   style="flex:1"
                   v-model="addressData"
                   @change="addressChange" />
        </el-col>
        <el-col :span="6"
                class="mb-10 flex"
                :key="'col'+index"
                v-else>
          <span class="search-name mr-15"
                v-if="item.name">{{item.name}}</span>
          <el-input :placeholder="item.placeholder"
                    v-if="item.type === 'input'"
                    clearable
                    class="flex1"
                    v-model="item.value">
            <i slot="suffix"
               class="el-input__icon el-icon-search"></i>
          </el-input>
          <el-select class="width100 flex1"
                     v-model="item.value"
                     clearable
                     placeholder="请选择搜索类型"
                     v-if="item.type === 'select'">
            <el-option v-for="option in item.list"
                       :key="option.value"
                       :label="option.label"
                       :value="option.value">
            </el-option>
          </el-select>
          <el-date-picker class="width100 flex1"
                          v-model="item.value"
                          value-format="yyyy-MM-dd HH:mm:ss"
                          v-if="item.type === 'date'"
                          type="datetime"
                          :placeholder="item.placeholder">
          </el-date-picker>
        </el-col>
      </template>
      <el-col :span="6"
              class="mb-10">
        <el-button @click="reset">重置</el-button>
        <el-button type="primary"
                   @click="searchD">查询</el-button>
      </el-col>
    </el-row>

  </div>
</template>

<script>
import Area from '@/components/Area'
import Address from '@/components/Address'
export default {
  props: {
    selectListName: String,
    selectList: {
      type: Array,
      default: () => {
        return []
      }
    },
    area: {
      type: Boolean,
      default: false
    },
    address: {
      type: Boolean,
      default: false
    },
    inputList: {
      type: Array,
      default: () => {
        return []
      }
    },
    params: Object,
    typeParams: Object,
    search: Array
  },
  data () {
    return {
      typeValue: '',
      selectValue: '',
      selectInput: '',
      searchParams: {},
      areaData: {
        city: '',
        district: '',
        net: '',
        province: ''
      },
      addressData: {
        city: '',
        district: '',
        province: ''
      }
    }
  },
  components: {
    Area,
    Address
  },
  filters: {
    typeName (type) {

    }
  },
  methods: {
    addressChange (res) {
      const { addressData } = this
      addressData.province = res.province
      addressData.city = res.city
      addressData.district = res.district
    },
    areaChange (res) {
      const { areaData } = this
      areaData.province = res.province
      areaData.city = res.city
      areaData.district = res.district
      areaData.net = res.net
      this.$emit('on-area-change', areaData)
    },
    searchD () {
      const searchParams = {}
      this.search.map((item) => {
        if (item.type === 'area') {
          searchParams.province = this.areaData.province
          searchParams.city = this.areaData.city
          searchParams.district = this.areaData.district
          searchParams.net = this.areaData.net
        } else if (item.type === 'address') {
          searchParams.addressProvince = this.addressData.province
          searchParams.addressCity = this.addressData.city
          searchParams.addressDistrict = this.addressData.district
        } else {
          searchParams[item.key] = item.value
        }
      })

      this.$emit('on-search', searchParams)
    },
    reset () {
      this.search.map((item) => {
        if (item.type === 'area') {
          this.areaData = {
            province: '',
            city: '',
            district: '',
            net: ''
          }
        } else if (item.type === 'address') {
          this.addressData = {
            province: '',
            city: '',
            district: ''
          }
        } else {
          item.value = ''
        }
      })
      const searchParams = {}
      this.search.map((item) => {
        if (item.type === 'area') {
          searchParams.province = this.areaData.province
          searchParams.city = this.areaData.city
          searchParams.district = this.areaData.district
          searchParams.net = this.areaData.net
        } else if (item.type === 'address') {
          searchParams.addressProvince = this.addressData.province
          searchParams.addressCity = this.addressData.city
          searchParams.addressDistrict = this.addressData.district
        } else {
          searchParams[item.key] = item.value
        }
      })

      this.$emit('reset', searchParams)
    }
  },
  mounted () {

  }
}
</script>

<style lang="scss" scoped>
.search-name {
  font-size: 14px;
  color: #606266;
}
.flex {
  display: flex;
  height: 40px;
  align-items: center;
}
.flex1 {
  flex: 1;
}
</style>
