<template>
  <div class="mt-15">
    <div v-if="search.length>0">
      <Search :search="search"
              @reset="reset"
              @on-area-change="areaChange"
              @on-search="searchTableData" />
    </div>
    <div class="tabel-header">
      <div class="left "
           style="flex:1">
        <slot name="button"></slot>
      </div>
      <div class="right"
           style="text-align:right">
        <div>
          <el-radio-group v-model="status"
                          @change="fetch(1)"
                          v-if="tabList">
            <el-radio-button :label="item.value"
                             v-for="item in tabList"
                             :key="item.value">{{item.label}}</el-radio-button>
          </el-radio-group>
        </div>
        <div class="mt-15">
          <slot name="tab-slot"></slot>
        </div>
      </div>

    </div>
    <el-table :data="tableData"
              v-loading="loading"
              class="mt-15"
              style="width: 100%">
      <slot></slot>
    </el-table>
    <el-pagination class="fr mt-15"
                   v-if="pageInfo"
                   @size-change="handleSizeChange"
                   @current-change="handleCurrentChange"
                   :current-page="currentPage"
                   :page-sizes="[10, 20, 30, 40]"
                   :page-size="pageInfo.size"
                   layout="total, sizes, prev, pager, next, jumper"
                   :total="pageInfo.total">
    </el-pagination>
  </div>
</template>

<script>
import Search from './search'
export default {
  props: {
    request: Function,
    dataName: String,
    searchParams: Object,
    tabList: Array,
    tabStatus: String,
    params: Object,
    init: {
      type: Boolean,
      default: false
    },
    search: {
      type: Array,
      default: () => []
    },
    searchFun: {
      type: Function,
      default: null
    },
    extraSearchParam: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      status: '',
      loading: false,
      pageInfo: {
        page: 1,
        size: 10
      },
      tableData: [],
      currentPage: 1,
      searchParam: null
    }
  },
  components: {
    Search
  },
  methods: {
    async fetch (page) {
      this.loading = true
      const { pageInfo, dataName, searchParams, searchParam, status, params } = this
      const requestParams = { ...searchParam, ...searchParams, ...params }
      if (page) {
        requestParams.page = page
      } else if (pageInfo) {
        requestParams.page = pageInfo.page
        requestParams.size = pageInfo.size
      }
      if (this.tabStatus) {
        requestParams[this.tabStatus] = status
      } else {
        requestParams.status = status
      }
      const res = await this.request(requestParams)
      this.loading = false
      if (res.meta.code === 0) {
        this.tableData = res.data[dataName]
        if (res.page_info) {
          this.pageInfo = res.page_info
          this.currentPage = res.page_info.page
        } else {
          this.pageInfo = null
        }

        this.$emit('on-success', res.data, this.searchParam)
      } else {
        this.$message.error(res.meta.msg)
      }
    },
    areaChange (res) {
      this.searchParam = res
      this.$emit('on-area-change', res)
    },
    handleSizeChange (val) {
      this.pageInfo.size = val
      this.fetch()
    },
    handleCurrentChange (val) {
      this.pageInfo.page = val
      this.fetch()
    },
    searchTableData (res) {
      if (this.searchFun) {
        this.searchParam = this.searchFun(res)
      } else {
        this.searchParam = res
      }
      if (this.extraSearchParam) {
        this.searchParam = { ...this.searchParam, ...this.extraSearchParam }
      }
      this.fetch()
      this.$emit('on-search', this.searchParam)
    },
    reset (res) {
      if (this.searchFun) {
        this.searchParam = this.searchFun(res)
      } else {
        this.searchParam = res
      }
    }
  },
  mounted () {
    if (!this.init) {
      this.fetch()
    }
  }
}
</script>

<style lang="scss" scoped>
.tabel-header {
  display: flex;
}
</style>
